import { Fragment, useEffect } from "react";
import { gsap } from "gsap/all";
import { Link } from "react-router-dom";
import "./Branding.scss";
import { Helmet } from "react-helmet";

function Branding() {
  useEffect(() => {
    gsap.to(".heading span", {
      y: 0,
      duration: 1,
      ease: "expo",
    });
  }, []);
  return (
    <Fragment>
      <Helmet>
        <title>IOS | Branding</title>
        <meta name="description" content="Micawber" />
        <meta name="keywords" content="Micawber" />
      </Helmet>

      <section id="branding">
        <img
          className="w-100"
          src="static/images/movies.jpg"
          alt=""
        />
        <div className="container mx-auto px-5 md:px-10 md:px-20 py-10 md:py-20 mb-20">
          <h1 className="heading anim text-center">
            <span>IN-FILM BRANDING</span>
          </h1>
          <p className="mt-10">
            IOS’s in-film branding division involves planning and executing
            in-film branding associations. We develop integrated campaigns which
            deliver value to the viewers and commercial impact for the brands. a
            customized 360-degree marketing plan for the promotion of the film,
            brand and their association in every project is undertaken through
            the following services:
          </p>
          <ul className="mt-10">
            <li>IN-FILM ASSOCIATIONS</li>
            <li>
              IN-BOOK BRANDING – FIRST SUCH INITIATIVE IN THE COUNTRY INVOLVING
              PICTORIAL INTEGRATIONS AND LOGO PLACEMENTS.
            </li>
          </ul>
          <h3 className="mt-16 text-3xl md:text-4xl font-thin">
            Major Projects Delivered:
          </h3>
          <ul className="mt-10">
            <li>BHANSALI PRODUCTIONS’ MC MARY KOM (FILM)</li>
            <li>EXCEL ENTERTAINMENT’S ROCK ON 2 (FILM)</li>
            <li>SACHIN TENDULKAR AUTOBIOGRAPHY (FILM)</li>
            <li>UNBREAKABLE (MC MARY KOM AUTOBIOGRAPHICAL BOOK)</li>
          </ul>

          <h3 className="mt-40 text-3xl md:text-4xl font-thin">
            Related Services
          </h3>
          <div className="mt-5 grid md:grid-cols-2 gap-10">
            <Link to="/sports">
              <button className="outline-btn w-full">Sports Marketing</button>
            </Link>
            <Link to="/event">
              <button className="outline-btn w-full">
                Events and Operations
              </button>
            </Link>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default Branding;
