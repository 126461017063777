import { Fragment, useEffect } from "react";
import { gsap } from "gsap/all";
import { Link } from "react-router-dom";
import "./Event.scss";
import { Helmet } from "react-helmet";

function Event() {
  useEffect(() => {
    gsap.to(".heading span", {
      y: 0,
      duration: 1,
      ease: "expo",
    });
  }, []);
  return (
    <Fragment>
      <Helmet>
        <title>IOS | Event</title>
        <meta name="description" content="Micawber" />
        <meta name="keywords" content="Micawber" />
      </Helmet>

      <section id="event">
        <img
          className="w-100"
          src="static/images/events.jpg"
          alt=""
        />
        <div className="container mx-auto px-5 md:px-10 md:px-20 py-10 md:py-20 mb-20">
          <h1 className="heading anim text-center">
            <span>EVENTS AND OPERATIONS</span>
          </h1>
          <p className="mt-10">
            We have a proven track record of delivering national and
            international events and are well positioned to work with sports
            franchises, sponsors, advertisers, marketers, rights holders,
            Government bodies and venues.
          </p>
          <h3 className="mt-10 text-4xl font-thin">Our Services Include:</h3>
          <table className="services-table mt-5">
            <tr>
              <td>01</td>
              <td>VENUE MANAGEMENT</td>
            </tr>
            <tr>
              <td>02</td>
              <td>LICENSING</td>
            </tr>
            <tr>
              <td>03</td>
              <td>TICKETING</td>
            </tr>
            <tr>
              <td>04</td>
              <td>BRANDING</td>
            </tr>
            <tr>
              <td>05</td>
              <td>SPONSOR SERVICING</td>
            </tr>
            <tr>
              <td>06</td>
              <td>PRODUCTION</td>
            </tr>
            <tr>
              <td>07</td>
              <td>ACTIVATIONS</td>
            </tr>
            <tr>
              <td>08</td>
              <td>PUBLIC RELATIONS AND COMMUNICATION</td>
            </tr>
            <tr>
              <td>09</td>
              <td>ACCREDITATION</td>
            </tr>
          </table>
          <p className="mt-10">
            We have successfully delivered major projects including stadium and
            team operations for Gujarat Lions (Indian Premier League), Delhi
            Dynamos (Indian Super League), Delhi Waveriders (Hockey India
            League) and Vijender Singh title fight night (an IOS event).
          </p>

          <h3 className="mt-40 text-3xl md:text-4xl font-thin">
            Related Services
          </h3>
          <div className="mt-5 grid md:grid-cols-2 gap-10">
            <Link to="/sports">
              <button className="outline-btn w-full">Sports Marketing</button>
            </Link>
            <Link to="/event">
              <button className="outline-btn w-full">
                Events and Operations
              </button>
            </Link>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default Event;
