import "./Navbar.scss";
import { gsap } from "gsap/all";
import { Fragment, useRef } from "react";
import { Link } from "react-router-dom";

function Navbar() {
  const header = useRef();
  const mq = window.matchMedia("(max-width: 768px)");
  const IS_SMALL_SCREEN = mq.matches;

  function openMenu() {
    gsap.set(".nav_links", { display: "flex" });
    gsap.from(".nav_links a,.nav_links button", {
      x: 200,
      stagger: 0.03,
      duration: 0.5,
      ease: "expo",
    });
  }
  function closeMenu() {
    gsap.set(".nav_links", { display: "none" });
  }

  const checkAndClose = () => {
    if (IS_SMALL_SCREEN) closeMenu();
  };

  return (
    <Fragment>
      <header>
        <nav ref={header} className="container mx-auto pl-5 py-3">
          <Link to="/" className="mob_logo">
            <img src="static/images/logo.png" alt="" />
          </Link>
          <div className="nav_links">
            <div className="close" onClick={checkAndClose}>
              <svg
                width="24"
                height="24"
                xmlns="http://www.w3.org/2000/svg"
                fillRule="evenodd"
                clipRule="evenodd"
              >
                <path d="M12 11.293l10.293-10.293.707.707-10.293 10.293 10.293 10.293-.707.707-10.293-10.293-10.293 10.293-.707-.707 10.293-10.293-10.293-10.293.707-.707 10.293 10.293z" />
              </svg>
            </div>
            <Link to="/about" onClick={checkAndClose}>
              About
            </Link>
            <Link to="/talent" onClick={checkAndClose}>
              Talent
            </Link>

            {/* <Link to={{
              pathname: '/',
              hash: '#services'
            }} onClick={checkAndClose}>
              Services
            </Link> */}

            <Link className="dropdown_wrapper">
              <span>IOS Services</span>
              <div className="dropdown">
                <Link to="/event" className="link" onClick={checkAndClose}>
                  Events & operations
                </Link>
                <Link to="/sports" className="link" onClick={checkAndClose}>
                  Sports Marketing
                </Link>
                <Link to="/client" className="link" onClick={checkAndClose}>
                  Client Servicing
                </Link>
                <Link to="/branding" className="link" onClick={checkAndClose}>
                  In-Film Branding
                </Link>
                <Link to="/talent-management" className="link" onClick={checkAndClose}>
                  Talent Management
                </Link>


              </div>
            </Link>

            <Link to="/" className="logo">
              <img src="static/images/logo.png" alt="" />
            </Link>
            <Link to="/blogs" onClick={checkAndClose}>
              Blog
            </Link>
            <Link to="/contact" onClick={checkAndClose}>
              Contact
            </Link>
            <Link to="/boxing" onClick={checkAndClose}><button>IOS BOXING</button></Link>
          </div>
          <div className="menu_open_btn mr-5 ml-3" onClick={openMenu}>
            <svg
              width="24"
              height="24"
              xmlns="http://www.w3.org/2000/svg"
              fillRule="evenodd"
              clipRule="evenodd"
            >
              <path
                d="M24 18v1h-24v-1h24zm0-6v1h-24v-1h24zm0-6v1h-24v-1h24z"
                fill="#1040e2"
              />
              <path d="M24 19h-24v-1h24v1zm0-6h-24v-1h24v1zm0-6h-24v-1h24v1z" />
            </svg>
          </div>
        </nav>
      </header>
    </Fragment>
  );
}

export default Navbar;
