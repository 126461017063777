import { Fragment, useEffect } from "react";
import { gsap } from "gsap/all";
import { Link } from "react-router-dom";
import "./Team.scss";
import { Helmet } from "react-helmet";

function Team() {
  useEffect(() => {
    gsap.timeline()
      .to("h1 span", {
        y: 0,
        duration: 1,
        ease: "expo",
      })
      .from("h3,p", {
        opacity: 0
      }, 0.2);
  }, []);
  return (
    <Fragment>
      <Helmet>
        <title>IOS | Team</title>
        <meta name="description" content="Micawber" />
        <meta name="keywords" content="Micawber" />
      </Helmet>

      <section id="team">
        <div className="container mx-auto px-5 md:px-10 md:px-20 py-10 md:py-20 mb-10 md:mb-20">
          <h1>
            <span>WE ARE TEAM IOS</span>
          </h1>
          <h3 className="text-white mt-5 md:mt-0 text-xl md:text-3xl text-center">
            Experience the Thrill of the Game, the Excitement of Entertainment!
          </h3>
          <p className="mt-10 md:mt-20">
            IOS Sports and Entertainment was founded in 2005 with the vision to
            improve the stature of sports in India. As a leader in the sports
            industry, we have worked on projects valued at over INR 2000 crores
            in the last decade.
          </p>
          <p className="mt-5">
            With offices in New Delhi, Mumbai, Bangalore and associates pan
            India, IOS has been a flagbearer for talent management. Along with
            athlete management, we have carved a niche as a one-stop agency for
            developing and managing a diverse portfolio of sports and
            entertainment properties. We have paved the way in the field of
            event management, marketing and in-film branding. Our operating
            model provides a 360-degree value add for all our stakeholders.
          </p>
          <p className="mt-5">
            India’s leading Sports and Entertainment Company, IOS is dedicated
            towards building and managing world-renowned talent, content and
            infrastructure. The key strength of IOS is a highly experienced,
            skilled and professional team, united and motivated by their passion
            for sports and entertainment.
          </p>
          <p className="mt-5">
            We also have a proven track record of delivering national and
            international events along with executing in-film branding
            associations. We are currently working with over 200 brands and
            managing the likes of some of the greatest names in the field of
            sports.
          </p>
          <div className="mt-28 flex flex-col md:flex-row justify-center items-center">
            <div className="col text-2xl md:text-5xl mb-10 md:mb-0">
              <p>"Managing the Game,</p>
              <p className="mt-3">Excelling in Sports!"</p>
            </div>
            {/* <div className="col">
              <img src="static/images/15_year_logo.png" alt="" />
            </div> */}
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default Team;
