import { Fragment, useEffect, useState } from "react";
import { gsap, ScrollTrigger } from "gsap/all";
import "./Talent.scss";
import { Helmet } from "react-helmet";
import TalentCard from "./../TalentCard/TalentCard";

function Talent() {
  gsap.registerPlugin(ScrollTrigger);
  const [players, setPlayers] = useState([]);

  const getTalent = () => {
    fetch("https://iosindia.com/api/player/")
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setPlayers(data);
        console.log(players);
      });
  };

  useEffect(() => {
    getTalent();
    gsap.to(".talent_banner h1 span", {
      y: 0,
      stagger: 0.1,
      duration: 1,
      ease: "expo",
    })
    gsap.utils.toArray(".talent_card").forEach((item, index) => {
      gsap.fromTo(
        item.querySelector(".curtain"),
        {
          scaleY: 1,
        },
        {
          scaleY: 0,
          duration: 1 + (index % 4) * 0.1,
          ease: "expo",
          scrollTrigger: {
            trigger: item,
            start: "top 80%",
            toggleActions: "play none none reverse",
          },
        }
      );
    });
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>IOS | Talent</title>
        <meta name="description" content="Micawber" />
        <meta name="keywords" content="Micawber" />
      </Helmet>

      <section id="talentSection">
        <div className="talent_banner">
          <div className="container mx-auto px-10 md:px-20 pb-5 md:py-32">
            <h1>
              <span>A SPECIAL</span>
            </h1>
            <h1>
              <span>PLACE FOR OUR</span>
            </h1>
            <h1>
              <span>MEMBERS</span>
            </h1>
          </div>
          <img src="static/images/talent-bg.png" alt="" />
        </div>
        <div className="container mx-auto px-10 md:px-20 py-10 md:py-20 mb-20">
          <div className="talent_grid md:mt-20">
            {players?.map((player) => (
              <TalentCard
                key={player.slug}
                img={player.image}
                name={player.name}
                sport={player.sport}
                location={player.location}
                medals={player.medals}
                fb={player.facebook_link}
                yt={player.youtube_link}
                tw={player.twitter_link}
                insta={player.instagram_link}
              ></TalentCard>
            ))}
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default Talent;
