import { Fragment, useEffect, useState } from "react";
import "./Blogs.scss";
import { Helmet } from "react-helmet";
import { gsap } from "gsap/all";
import { Link, useParams } from "react-router-dom";

function Blogs(props) {
  const params = useParams();

  const [blogs, setBlogs] = useState([]);
  function getBlogs() {
    fetch("https://iosindia.com/api/update/")
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setBlogs(data);
      });
  }

  useEffect(() => {
    gsap.to(".heading span", {
      y: 0,
      duration: 1,
      ease: "expo",
    });
  }, []);

  useEffect(() => {
    getBlogs();
    console.log("params", params);
  }, [params]);

  return (
    <Fragment>
      <Helmet>
        <title>IOS | News & Blogs</title>
        <meta name="description" content="Micawber" />
        <meta name="keywords" content="Micawber" />
      </Helmet>

      <section id="newsBlogs">
        {params.blogId ? (
          <div className="blog_detail container mx-auto px-10 md:px-20 py-10 md:py-20 mb-20">
            {blogs.map((blog) =>
              params.blogId
                ? blog.slug == params.blogId && (
                  <Fragment>
                    <Helmet>
                      <title>{blog.meta_title}</title>
                      <meta
                        name="description"
                        content={blog.meta_description}
                      />
                    </Helmet>
                    <h1 className="mb-2 text-4xl">{blog.title}</h1>
                    <div className="seperator"></div>
                    <small className="date">{blog.published_on}</small>
                    <img className="mt-10 mb-2" src={blog.image} alt="" />
                    <p
                      className="mt-10"
                      dangerouslySetInnerHTML={{
                        __html: blog.content,
                      }}
                    ></p>

                    <h1 className="mt-20 mb-2 text-4xl">LATEST</h1>
                    <div className="seperator"></div>

                    <ul className="mt-10">
                      {blogs.map((b) =>
                        b.slug == blog.slug ? null : <li>
                          <Link to={"/blogs/" + b.slug}>
                            <div className="latest_card">
                              <img src={b.image} />
                              <div className="col">
                                <h3 className="text-xl md:text-2xl">
                                  {b.title}
                                </h3>
                                <p>{b.short_description}</p>
                                <small>{b.published_on}</small>
                              </div>
                            </div>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </Fragment>
                )
                : null
            )}
          </div>
        ) : (
          <div className="container mx-auto px-10 md:px-20 py-10 md:py-20 mb-20">
            <h1 className="heading anim text-center">
              <span>News & Blogs</span>
            </h1>
            <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 gap-5">
              {blogs.map((blog) => (
                <Link to={"/blogs/" + blog.slug}>
                  <div className="card">
                    <div className="img">
                      <img src={blog.image} alt="" />
                    </div>
                    <div className="body">
                      <small>{blog.published_on}</small>
                      <h3>{blog.title}</h3>
                      <p>{blog.short_description}</p>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        )}
      </section>
    </Fragment>
  );
}

export default Blogs;
