import { Fragment, useEffect, useState, useRef } from "react";
import { gsap, ScrollTrigger } from "gsap/all";
import "./Boxing.scss";
import { Helmet } from "react-helmet";
import Carousel from "../Carousel/Carousel";

function Boxing(props) {
  const boxingRef = useRef();
  const [images, setImages] = useState([]);

  const getImages = () => {
    fetch("https://iosindia.com/api/boxing-images/")
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setImages(data);
        console.log(images);
      });
  };

  useEffect(() => {
    getImages()
    const boxingQ = gsap.utils.selector(boxingRef);
    gsap.from(boxingQ("img"), {
      scale: 1.35
    });
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>IOS | Boxing</title>
        <meta name="description" content="Micawber" />
        <meta name="keywords" content="Micawber" />
      </Helmet>
      <section id="boxing">
        <div className="container mx-auto px-10 md:px-20 py-20">
          <h1 className="heading">
            IOS BOXING
            <div className="bg">IOS BOXING</div>
          </h1>
          <div className="-mt-10 md:-mt-32 flex justify-center items-center">
            <div className="center" ref={boxingRef}>
              <img
                className="mx-auto md:max-w-4xl"
                src="static/images/vijendra-trans 2.png"
                alt=""
              />
            </div>
          </div>

          <h2 className="mt-10 text-4xl font-thin">About IOS Boxing</h2>
          <p className="mt-5 mb-16">
            Launched in 2015, IOS Boxing Promotions aims at promoting
            professional boxing in India and bringing it at par with other
            sports in the country. IOS Boxing is also deeply committed to
            identifying, supporting and promoting talented Indian boxers across
            the world. Our esteemed roster includes Vijender Singh, Akhil Kumar,
            Jitender Kumar, Kudeep Dhanda, Pardeep Kharera and Dharmender Grewal
            to name a few.
          </p>

          <div className="carousel_header text-2xl md:text-4xl px-10 pt-2 pb-1">
            VIJENDER SINGH FIGHTS
          </div>
          <Carousel slides={images} onlyimage={true} height="58vw"></Carousel>
        </div>
      </section>
    </Fragment>
  );
}

export default Boxing;
