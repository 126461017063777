import { Fragment, useEffect } from "react";
import { gsap } from "gsap/all";
import { Link } from "react-router-dom";
import "./TalentManagement.scss";
import { Helmet } from "react-helmet";

function TalentManagement() {
  useEffect(() => {
    gsap.to(".heading span", {
      y: 0,
      duration: 1,
      ease: "expo",
    });
  }, []);
  return (
    <Fragment>
      <Helmet>
        <title>IOS | Talent Management</title>
        <meta name="description" content="Micawber" />
        <meta name="keywords" content="Micawber" />
      </Helmet>

      <section id="talentManagement">
        <img className="w-100" src="static/images/talent.jpg" alt="" />
        <div className="container mx-auto px-10 md:px-20 py-20 mb-20">
          <h1 className="heading anim text-center">
            <span>Talent Management</span>
          </h1>
          <p className="mt-10">
            IOS’s Talent Management division focuses on building a unique brand
            identity for the athlete. We drive revenue through endorsements,
            brand collaborations and marketing partnerships. The athlete is the
            direct beneficiary in this process. Through our years of experience
            and in-depth expertise, we have created some of the most
            recognizable campaigns for brands across India. We believe in the
            process of becoming the voice of our clients and reaching the right
            audience through our exclusive athletes. This allows us to create a
            brand identity for the athlete.
          </p>

          <h3 className="mt-40 text-4xl font-thin">Related Services</h3>
          <div className="mt-5 grid grid-cols-2 gap-10">
            <Link to="/sports">
              <button className="outline-btn w-full">Sports Marketing</button>
            </Link>
            <Link to="/event">
              <button className="outline-btn w-full">
                Events and Operations
              </button>
            </Link>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default TalentManagement;
