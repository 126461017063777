import { Fragment, useEffect } from "react";
import { gsap } from "gsap/all";
import { Link } from "react-router-dom";
import "./Client.scss";
import { Helmet } from "react-helmet";

function Client() {
  useEffect(() => {
    gsap.to(".heading span", {
      y: 0,
      duration: 1,
      ease: "expo",
    });
  }, []);
  return (
    <Fragment>
      <Helmet>
        <title>IOS | Client Servicing</title>
        <meta name="description" content="Micawber" />
        <meta name="keywords" content="Micawber" />
      </Helmet>

      <section id="client">
        <img
          className="w-100"
          src="static/images/client-servicing.jpg"
          alt=""
        />
        <div className="container mx-auto px-5 md:px-10 md:px-20 py-10 md:py-20 mb-20">
          <h1 className="heading anim text-center">
            <span>CLIENT SERVICING</span>
          </h1>

          <ul className="mt-10">
            <li>
              Our client servicing division connects the corporate sales team
              with the operations team in servicing accounts and forms the
              primary point of contact for clients.{" "}
            </li>
            <li>
              We strategize end-to-end solutions to marketing ideas for various
              associated brands in the domain of sports.
            </li>
            <li>
              We are dedicated to ensuring clients remain updated on any new
              opportunities with the organization and resolving client queries
              regarding the services offered.
            </li>
          </ul>
          {/* <h3 className="mt-16 text-3xl md:text-4xl font-thin">
            We Create With Some Of The Biggest Brands In The World Of Sports.
          </h3>
          <img className="mt-5" src="static/images/sports.png" alt="" /> */}
          <h3 className="mt-16 text-3xl md:text-4xl font-thin">
            Related Services
          </h3>
          <div className="mt-5 grid md:grid-cols-2 gap-10">
            <Link to="/sports">
              <button className="outline-btn w-full">Sports Marketing</button>
            </Link>
            <Link to="/event">
              <button className="outline-btn w-full">
                Events and Operations
              </button>
            </Link>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default Client;
