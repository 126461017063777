import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.scss";
import ScrollToTop from "./ScrollToTop";
import Navbar from "./components/Navbar/Navbar";
import Home from "./components/Home/Home";
import Footer from "./components/Footer/Footer";
import Team from "./components/Team/Team";
import Talent from "./components/Talent/Talent";
import Event from "./components/Event/Event";
import Sports from "./components/Sports/Sports";
import Client from "./components/Client/Client";
import Branding from "./components/Branding/Branding";
import TalentManagement from "./components/TalentManagement/TalentManagement";
import Boxing from "./components/Boxing/Boxing";
import Blogs from "./components/Blogs/Blogs";
import Contact from "./components/Contact/Contact";

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <ScrollToTop>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/about" element={<Team />} />
          <Route path="/talent" element={<Talent />} />
          <Route path="/event" element={<Event />} />
          <Route path="/sports" element={<Sports />} />
          <Route path="/client" element={<Client />} />
          <Route path="/branding" element={<Branding />} />
          <Route path="/boxing" element={<Boxing />} />
          <Route path="/blogs" element={<Blogs />}>
            <Route path=":blogId"></Route>
          </Route>
          <Route path="/contact" element={<Contact />} />
          <Route path="/talent-management" element={<TalentManagement />} />
        </Routes>
      </ScrollToTop>
      <Footer></Footer>
    </BrowserRouter>
  );
}

export default App;
