import { Fragment, useEffect, useState, useRef } from "react";
import { gsap, ScrollTrigger, ScrollToPlugin } from "gsap/all";
import { Link } from "react-router-dom";
import "./Home.scss";
import { Helmet } from "react-helmet";
import TalentCard from "../TalentCard/TalentCard";
import Carousel from "./../Carousel/Carousel";

function Home(props) {
  const [banners, setBanners] = useState([]);
  const [players, setPlayers] = useState([]);
  const [logos, setLogos] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const boxingRef = useRef();
  const isMobile = window.matchMedia(
    "only screen and (max-width: 760px)"
  ).matches;

  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(ScrollToPlugin);

  const getBanners = () => {
    fetch("https://iosindia.com/api/banners/")
      .then((res) => res.json())
      .then((data) => {
        setBanners(data);
      });
  };

  const getTalent = () => {
    fetch("https://iosindia.com/api/player/")
      .then((res) => res.json())
      .then((data) => {
        setPlayers(data);
      });
  };

  const getLogos = () => {
    fetch("https://iosindia.com/api/update/")
      .then((res) => res.json())
      .then((data) => {
        setBlogs(data);
      });
  };

  const getBlogs = () => {
    fetch("https://iosindia.com/api/logos/")
      .then((res) => res.json())
      .then((data) => {
        setLogos(data);
      });
  };

  const updateNext = () => {
    const elm = document.querySelector("#updates .carousel");
    const hor_shift = elm.scrollWidth / 4;
    gsap.to("#updates .carousel", {
      scrollTo: { x: hor_shift },
      ease: "sine",
      onUpdate: () => {
        gsap.to("#updates .progress .bar", {
          scaleX: (elm.clientWidth + elm.scrollLeft) / elm.scrollWidth,
          ease: "sine",
        });
      },
    });
  };

  const updatePrev = () => {
    const elm = document.querySelector("#updates .carousel");
    const hor_shift = elm.scrollWidth / 4;
    gsap.to("#updates .carousel", {
      scrollTo: { x: -hor_shift },
      ease: "sine",
      onUpdate: () => {
        gsap.to("#updates .progress .bar", {
          scaleX: (elm.clientWidth + elm.scrollLeft) / elm.scrollWidth,
          ease: "sine",
        });
      },
    });
  };

  useEffect(() => {
    getBanners();
    getTalent();
    getLogos();
    getBlogs();
    const elm = document.querySelector("#updates .carousel");
    gsap.to("#updates .progress .bar", {
      scaleX: (elm.clientWidth + elm.scrollLeft) / elm.scrollWidth,
      ease: "sine",
    });
    const boxingQ = gsap.utils.selector(boxingRef);
    gsap
      .timeline()
      .from(".hero_banner img", {
        opacity: 0,
        scale: 1.1,
      })
      .to(".hero_banner .name span", {
        y: 0,
        stagger: 0.1,
        duration: 1,
        ease: "expo",
      })
      .from(
        ".hero_banner .title",
        {
          opacity: 0,
        },
        1
      )
      .from(".hero_banner .scroll_down", {
        scale: 0,
        ease: "expo",
      });

    gsap.to(boxingQ(".center img"), {
      scale: 1,
      scrollTrigger: {
        trigger: boxingQ(".container"),
        scrub: true,
        // end: "center",
      },
    });

    gsap.fromTo(
      "#services .grid > .col",
      {
        yPercent: 100,
      },
      {
        yPercent: 0,
        stagger: 0.1,
        ease: "expo",
        scrollTrigger: {
          trigger: "#services .grid",
          // scrub: true
          // start: "top 80%",
          toggleActions: "play none none reverse",
        },
      }
    );

    gsap.utils.toArray(".heading").forEach((item, index) => {
      gsap
        .timeline()
        .from(item, {
          x: 50,
          scrollTrigger: {
            trigger: item,
            scrub: true,
          },
        })
        .from(
          item.querySelector(".bg"),
          {
            x: 150,
            scrollTrigger: {
              trigger: item,
              scrub: true,
            },
          },
          0
        );
    });
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>IOS</title>
        <meta name="description" content="Micawber" />
        <meta name="keywords" content="Micawber" />
      </Helmet>

      <section className="hero_banner">
        <Carousel slides={banners} height="58vw"></Carousel>
        <div className="scroll_down">
          <svg
            width="101"
            height="101"
            viewBox="0 0 101 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="50.5" cy="50.5" r="50.5" fill="#DA1B22" />
            <g clipPath="url(#clip0_346_2)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M62.3435 74.2818C62.3435 74.8183 61.9086 75.2533 61.3721 75.2533L59.9006 75.2533C55.0611 75.2533 51.1296 79.1441 51.0656 83.9696C51.0661 84.0094 51.0663 84.0492 51.0663 84.0891C51.0663 84.6256 50.6314 85.0605 50.0949 85.0605C50.081 85.0605 50.0672 85.0602 50.0535 85.0597C49.5356 85.0386 49.1221 84.6121 49.122 84.089C49.1221 84.0491 49.1222 84.0094 49.1227 83.9696C49.0588 79.144 45.1272 75.2533 40.2878 75.2533L38.8176 75.2533C38.2813 75.2533 37.8464 74.8184 37.8463 74.2819C37.8463 73.7454 38.2812 73.3105 38.8177 73.3105L40.2877 73.3105C43.8593 73.3105 47.0255 75.0477 48.9869 77.7235L48.9869 56.3876C48.9869 55.8512 49.4219 55.4163 49.9583 55.4162C50.4948 55.4162 50.9298 55.8512 50.9298 56.3877L50.9298 78.1116C52.8625 75.2168 56.1591 73.3104 59.9006 73.3104L61.3721 73.3104C61.9086 73.3104 62.3435 73.7453 62.3435 74.2818Z"
                fill="white"
              />
            </g>
            <path
              d="M28.314 34.82C28.188 31.994 26.082 30.842 23.4 30.842C21.366 30.842 19.08 31.796 19.08 34.262C19.08 36.71 21.276 37.232 23.454 37.682C25.65 38.132 27.846 38.51 27.846 40.688C27.846 42.92 25.668 43.622 24.12 43.622C21.582 43.622 19.332 42.56 19.494 39.752H18.702C18.666 43.064 21.114 44.306 24.12 44.306C26.082 44.306 28.638 43.352 28.638 40.688C28.638 38.132 26.442 37.574 24.246 37.124C22.068 36.674 19.872 36.332 19.872 34.262C19.872 32.138 21.942 31.526 23.4 31.526C25.776 31.526 27.432 32.624 27.522 34.82H28.314ZM40.9119 34.982C40.8939 34.802 40.2999 30.842 35.7459 30.842C31.8939 30.842 29.6259 33.812 29.6259 37.574C29.6259 41.336 31.8939 44.306 35.7459 44.306C38.5719 44.306 40.8399 42.056 41.1279 38.996H40.3359C40.1919 41.39 38.2479 43.622 35.7459 43.622C32.3799 43.622 30.4179 40.868 30.4179 37.574C30.4179 34.28 32.3799 31.526 35.7459 31.526C37.8879 31.526 39.7779 32.822 40.1199 34.982H40.9119ZM43.2177 37.466V31.832H47.7897C49.5177 31.832 51.0657 32.57 51.0657 34.514C51.0657 36.584 49.6617 37.466 47.7897 37.466H43.2177ZM42.4257 44H43.2177V38.15H47.7897C50.0397 38.15 50.6517 39.158 50.8137 40.4C50.9577 41.642 50.6697 43.118 51.1557 44H52.0377C51.5157 43.316 51.7317 41.876 51.6057 40.544C51.4797 39.212 51.0117 38.006 49.1037 37.79V37.754C50.8137 37.466 51.8577 36.188 51.8577 34.46C51.8577 31.976 50.0397 31.148 47.7897 31.148H42.4257V44ZM65.0496 37.574C65.0496 33.812 62.7816 30.842 58.9476 30.842C55.0956 30.842 52.8276 33.812 52.8276 37.574C52.8276 41.336 55.0956 44.306 58.9476 44.306C62.7816 44.306 65.0496 41.336 65.0496 37.574ZM53.6196 37.574C53.6196 34.28 55.5816 31.526 58.9476 31.526C62.2956 31.526 64.2576 34.28 64.2576 37.574C64.2576 40.868 62.2956 43.622 58.9476 43.622C55.5816 43.622 53.6196 40.868 53.6196 37.574ZM66.3854 44H74.4674V43.316H67.1774V31.148H66.3854V44ZM75.0254 44H83.1074V43.316H75.8174V31.148H75.0254V44Z"
              fill="white"
            />
            <defs>
              <clipPath id="clip0_346_2">
                <rect
                  width="34"
                  height="34"
                  fill="white"
                  transform="translate(74 71.0416) rotate(135)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </section>

      {/* <section className="hero_banner">
        <div className="slide">
          <img src="static/images/b1.jpg" alt="" />
          <div className="l1">
            <div className="container mx-auto px-10 md:px-20">
              <h2 className="name">
                <span>Vijender</span>
              </h2>
              <h2 className="name">
                <span>Singh</span>
              </h2>
              <p className="title mt-5">Professional boxer</p>
            </div>
          </div>
        </div>
      </section> */}

      <section id="about" className="-mt-40 pt-40">
        <div className="container mx-auto px-10 md:px-20 py-10 md:py-20">
          <div className="flex flex-col-reverse md:flex-row items-center -mr-10">
            <div className="col">
              <p>
                We are a one-stop agency for the development and management of a
                diverse portfolio of sports and entertainment properties. Our
                operating model provides a 360-degree value add for all our
                stakeholders.
              </p>
              <p className="mt-8">
                India’s leading Sports and Entertainment Company, IOS is
                dedicated towards building and managing world-renowned talent,
                content and infrastructure.
              </p>
              <Link to="/about">
                <button className="p_btn mt-8">Read More</button>
              </Link>
            </div>
            <div className="col mb-10 md:mb-0 md:ml-10">
              <h1 className="heading">
                ABOUT US
                <div className="bg black-bg left">ABOUT US</div>
              </h1>
            </div>
          </div>
        </div>
      </section>

      <section id="talent">
        <div className="container mx-auto px-10 md:px-20 py-10 md:py-20">
          <h1 className="heading">
            TALENT
            <div className="bg black-bg">TALENT</div>
          </h1>
          <div className="talent_grid mt-10 md:mt-20">
            {players?.slice(0, 16).map((player) => (
              <TalentCard
                key={player.slug}
                img={player.image}
                name={player.name}
                sport={player.sport}
                location={player.location}
                medals={player.medals}
                fb={player.facebook_link}
                yt={player.youtube_link}
                tw={player.twitter_link}
                insta={player.instagram_link}
              ></TalentCard>
            ))}
          </div>
          <div className="flex justify-end mt-10">
            <Link to="/talent">
              {" "}
              <button className="p_btn">View More</button>
            </Link>
          </div>
        </div>
      </section>

      <section id="boxing" ref={boxingRef}>
        <div className="container mx-auto px-10 md:px-20 py-10 md:py-20">
          <h1 className="heading">
            IOS BOXING
            <div className="bg">IOS BOXING</div>
          </h1>
          <div className="flex flex-col md:flex-row items-center md:-mt-32 md:-ml-10">
            <div className="left">Fight for Greatness</div>
            <div className="center">
              <img src="static/images/vijendra-trans 2.png" alt="" />
            </div>
            <div className="right">
              <p>
                Launched in 2015, IOS Boxing Promotions aims at promoting
                professional boxing in India and bringing it at par with other
                sports in the country.
              </p>
              <Link to="/boxing">
                <button className="p_btn mt-5">Read More</button>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section id="services">
        <div className="container mx-auto px-10 md:px-20 pt-10 md:py-20">
          <div className="flex flex-col md:flex-row items-center mb-10 md:mb-5">
            <p className="text-white text-5xl">Grow beyond</p>
            <p className="mt-10 md:mt-0 md:ml-10 text-xl">
              Sports marketing services aim to help sports organizations, teams,
              and athletes to effectively promote their brand, engage with fans,
              and generate revenue through various marketing channels.
            </p>
          </div>
          <h1 className="heading md:text-right text-white">
            IOS SERVICE
            <div className="bg left red-bg">IOS SERVICE</div>
          </h1>
        </div>
        <div className="mt-10 md:mt-0 grid md:grid-cols-4">
          <div className="col px-5 pt-10 pb-10 md:pb-20">
            <div className="bg"></div>
            <h3>
              Events &<br></br>operations
            </h3>
            <p className="my-5 md:my-10">
              We have a proven track record of delivering national and
              international events and are well positioned to work with sports
              franchises, sponsors, advertisers, marketers, rights holders,
              government bodies and venues.
            </p>
            <Link to="/event">
              <button className="p_btn">Read More</button>
            </Link>
          </div>
          <div className="col px-5 pt-10 pb-10 md:pb-20">
            <div className="bg"></div>
            <h3>
              Sports<br></br>Marketing
            </h3>
            <p className="my-5 md:my-10">
              We have a proven track record of delivering national and
              international events and are well positioned to work with sports
              franchises, sponsors, advertisers, marketers, rights holders,
              government bodies and venues.
            </p>
            <Link to="/sports">
              <button className="p_btn mt-5">Read More</button>
            </Link>
          </div>
          <div className="col px-5 pt-10 pb-10 md:pb-20">
            <div className="bg"></div>
            <h3>
              Client<br></br>Servicing
            </h3>
            <p className="my-5 md:my-10">
              We have a proven track record of delivering national and
              international events and are well positioned to work with sports
              franchises, sponsors, advertisers, marketers, rights holders,
              government bodies and venues.
            </p>
            <Link to="/client">
              <button className="p_btn mt-5">Read More</button>
            </Link>
          </div>
          <div className="col px-5 pt-10 pb-10 md:pb-20">
            <div className="bg"></div>
            <h3>
              In-Film<br></br>
              Branding
            </h3>
            <p className="my-5 md:my-10">
              We have a proven track record of delivering national and
              international events and are well positioned to work with sports
              franchises, sponsors, advertisers, marketers, rights holders,
              government bodies and venues.
            </p>
            <Link to="/branding">
              <button className="p_btn mt-5">Read More</button>
            </Link>
          </div>
        </div>
      </section>

      <section id="brands">
        <div className="container mx-auto px-10 md:px-20 py-10 md:py-20">
          <div className="text-3xl md:text-5xl max-w-md mb-10 capitalize">
            Welcome to the house of iconic brands
          </div>
          <h1 className="heading">
            BIGGEST BRANDS
            <div className="bg">BIGGEST BRANDS</div>
          </h1>
          <div className="mt-10 flex flex-wrap justify-center logos_grid">
            {logos.map((logo) => (
              <img className="my-3 md:my-8" src={logo.image}></img>
            ))}
          </div>
          {/* <img className="mt-10" src="static/images/clients.png" alt="" /> */}
        </div>
      </section>

      <section id="updates">
        <div className="container mx-auto md:px-10 md:px-20 py-10 md:pt-20">
          <h1 className="heading md:text-right px-10 md:px-0">
            LATEST UPDATES
            <div className="bg left">LATEST UPDATES</div>
          </h1>
        </div>
        <div className="pb-10 md:pb-20">
          <div className="carousel mt-10 pl-10 md:pl-0">
            {blogs.map((update) => (
              <Link to={"/blogs/" + update.slug} className="card">
                <img src={update.image} alt="" />
                <div className="body px-5 pb-5 pt-2">
                  <p className="date">{update.published_on}</p>
                  <h3 className="mt-2">{update.title}</h3>
                  <p className="desc mt-5">{update.short_description}</p>
                </div>
              </Link>
            ))}
          </div>
          <div className="container mx-auto mt-10 nav">
            <div className="progress">
              <div className="bar"></div>
            </div>
            <button onClick={updatePrev} className="ml-10">
              <svg
                width="65"
                height="65"
                viewBox="0 0 65 65"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="65"
                  height="65"
                  rx="32.5"
                  transform="matrix(-1 0 0 1 65 0)"
                  fill="black"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M27.4029 15.2469C26.6385 15.2563 26.0211 15.8836 26.024 16.6481L26.0318 18.7447C26.0576 25.6404 20.535 31.3104 13.6601 31.486C13.6035 31.486 13.5467 31.4864 13.4899 31.4871C12.7255 31.4966 12.1082 32.1238 12.1111 32.8882C12.111 32.9081 12.1116 32.9277 12.1124 32.9473C12.1451 33.6849 12.755 34.2666 13.5004 34.2574C13.5572 34.2567 13.6138 34.2558 13.6704 34.2544C20.5461 34.261 26.1104 39.7949 26.1361 46.6903L26.144 48.7851C26.1468 49.5493 26.7688 50.1614 27.5331 50.1521C28.2976 50.1427 28.915 49.5154 28.9121 48.7509L28.9043 46.6564C28.8853 41.5674 26.3933 37.0863 22.5704 34.3385L52.969 33.9649C53.7333 33.9554 54.3506 33.3281 54.3479 32.5638C54.345 31.7993 53.723 31.1872 52.9586 31.1965L22.007 31.577C26.1213 28.7725 28.8198 24.0418 28.7999 18.7107L28.7921 16.6141C28.7892 15.8496 28.1673 15.2375 27.4029 15.2469Z"
                  fill="white"
                />
              </svg>
            </button>
            <button onClick={updateNext} className="ml-1">
              <svg
                width="65"
                height="65"
                viewBox="0 0 65 65"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="65" height="65" rx="32.5" fill="black" />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M37.5971 15.2469C38.3615 15.2563 38.9789 15.8836 38.976 16.6481L38.9682 18.7447C38.9424 25.6404 44.465 31.3104 51.3399 31.486C51.3965 31.486 51.4533 31.4864 51.5101 31.4871C52.2745 31.4966 52.8918 32.1238 52.8889 32.8882C52.889 32.9081 52.8884 32.9277 52.8876 32.9473C52.8549 33.6849 52.245 34.2666 51.4996 34.2574C51.4428 34.2567 51.3862 34.2558 51.3296 34.2544C44.4539 34.261 38.8896 39.7949 38.8639 46.6903L38.856 48.7851C38.8532 49.5493 38.2312 50.1614 37.4669 50.1521C36.7024 50.1427 36.085 49.5154 36.0879 48.7509L36.0957 46.6564C36.1147 41.5674 38.6067 37.0863 42.4296 34.3385L12.031 33.9649C11.2667 33.9554 10.6494 33.3281 10.6521 32.5638C10.655 31.7993 11.277 31.1872 12.0414 31.1965L42.993 31.577C38.8787 28.7725 36.1802 24.0418 36.2001 18.7107L36.2079 16.6141C36.2108 15.8496 36.8327 15.2375 37.5971 15.2469Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default Home;
