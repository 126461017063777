import { Fragment, useEffect } from "react";
import { gsap } from "gsap/all";
import { Link } from "react-router-dom";
import "./Sports.scss";
import { Helmet } from "react-helmet";

function Sports() {
  useEffect(() => {
    gsap.to(".heading span", {
      y: 0,
      duration: 1,
      ease: "expo",
    });
  }, []);
  return (
    <Fragment>
      <Helmet>
        <title>IOS | Sports Marketing</title>
        <meta name="description" content="Micawber" />
        <meta name="keywords" content="Micawber" />
      </Helmet>

      <section id="sports">
        <img
          className="w-100"
          src="static/images/sports-marketing.jpg"
          alt=""
        />
        <div className="container mx-auto px-5 md:px-10 md:px-20 py-10 md:py-20 mb-20">
          <h1 className="heading anim text-center">
            <span>SPORTS MARKETING</span>
          </h1>

          <p className="mt-10">
            As an expert in sports marketing, IOS helps brands reach their
            marketing objectives through smart product and brand integrations.
            Our integrated sports marketing campaigns enable us to achieve
            client needs like enhancing brand visibility or launching a new
            product/service. Our 360-degree actionable approach provides unique
            solutions for investing in sports properties.
          </p>
          <p className="mt-10">
            We have helped clients across a variety of industries with their
            corporate branding and digital marketing needs, through critical
            strategic planning with responsive technical execution. The
            franchises that we have worked in the past include Gujarat Lions
            (IPL), Delhi Dynamos (ISL) and the Delhi Waveriders (HIL). IOS has
            worked with over 100 different brands for sports sponsorship.
          </p>
          <h3 className="mt-16 text-3xl md:text-4xl font-thin">
            Our Services Include:
          </h3>
          <table className="services-table mt-5">
            <tr>
              <td>01</td>
              <td>IDENTIFYING THE RIGHT OPPORTUNITY</td>
            </tr>
            <tr>
              <td>02</td>
              <td>STRATEGIZING</td>
            </tr>
            <tr>
              <td>03</td>
              <td>PLANNING</td>
            </tr>
            <tr>
              <td>04</td>
              <td>EXECUTION</td>
            </tr>
          </table>

          {/* <h3 className="mt-16 text-3xl md:text-4xl font-thin">
            We Create With Some Of The Biggest Brands In The World Of Sports.
          </h3>
          <img className="mt-5" src="static/images/sports.png" alt="" /> */}

          <h3 className="mt-16 text-3xl md:text-4xl font-thin">
            Related Services
          </h3>
          <div className="mt-5 grid md:grid-cols-2 gap-10">
            <Link to="/event">
              <button className="outline-btn w-full">
                Events and Operations
              </button>
            </Link>
            <Link to="/client">
              <button className="outline-btn w-full">Client Servicing</button>
            </Link>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default Sports;
