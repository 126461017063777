import { Fragment, useEffect, useRef, useState } from "react";
import "./Contact.scss";
import { Helmet } from "react-helmet";
import { gsap } from "gsap/all";

function Contact(props) {
  const form = useRef();
  const [querySent, setQuerySent] = useState(false);
  async function sendQuery(e) {
    e.preventDefault();
    let formData = new FormData(form.current);
    // fetch("http://localhost:8000/api/query/", {
    fetch("https://iosindia.com/api/query/", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        form.current.reset();
        setQuerySent(true);
        window.scrollTo(0, 0);
      });
  }
  useEffect(() => {
    gsap
      .timeline()
      .to(".heading span", {
        y: 0,
        duration: 1,
        stagger: 0.1,
        ease: "expo",
      })
      .from(
        ".opacity",
        {
          opacity: 0,
          stagger: 0.1,
        },
        0.5
      );
  }, [querySent]);

  return (
    <Fragment>
      <Helmet>
        <title>IOS | Contact</title>
        <meta name="description" content="Micawber" />
        <meta name="keywords" content="Micawber" />
      </Helmet>

      <section id="contact">
        <div className="container mx-auto px-5 md:px-10 md:px-20 py-10 md:py-20 mb-20">
          {querySent ? (
            <Fragment>
              <h1 className="mt-10 text-xl text-center">
                We've received your message. We'll get get back to you soon.
              </h1>
              <button
                className="p_btn mt-10 mx-auto block"
                onClick={() => setQuerySent(false)}
              >
                Done
              </button>
            </Fragment>
          ) : (
            <Fragment>
              <h1 className="heading anim">
                <span>LET'S</span>
              </h1>
              <h1 className="heading anim">
                <span>WORK</span>
              </h1>
              <h1 className="heading anim">
                <span>TOGETHER</span>
              </h1>
              <p className="text-md md:text-3xl opacity">
                Send us your enquiry and we’ll respond within 1-2 working days.
              </p>

              <div className="flex md:items-center flex-col md:flex-row my-5 md:mt-10 opacity">
                <div className="col flex-1 md:mr-10 mb-10 md:mb-0">
                  <svg
                    className="w-10 mb-5"
                    viewBox="0 0 93 135"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M31.9485 108.992C12.1753 86.4132 2.20997 65.222 2.20997 45.3575C2.20997 20.308 22.5167 0 47.5675 0C72.6183 0 92.925 20.3067 92.925 45.3575C92.925 65.2751 82.9055 86.5277 63.0246 109.176C80.6081 110.889 92.925 115.391 92.925 121.69C92.925 129.826 72.3705 134.965 46.4625 134.965C20.5545 134.965 0 129.826 0 121.69C0 115.149 13.2828 110.545 31.951 108.991L31.9485 108.992ZM35.7087 113.174C28.1339 113.653 21.2347 114.632 15.7072 116.014C11.863 116.974 8.82573 118.098 6.81273 119.288C5.13065 120.282 4.42245 121.148 4.42245 121.69C4.42245 122.231 5.13065 123.097 6.81273 124.091C8.8257 125.281 11.863 126.405 15.7072 127.365C23.763 129.379 34.7347 130.54 46.4597 130.54C58.1847 130.54 69.1567 129.379 77.2122 127.365C81.0564 126.405 84.0937 125.281 86.1067 124.091C87.7888 123.097 88.497 122.231 88.497 121.69C88.497 121.148 87.7888 120.282 86.1067 119.288C84.0938 118.098 81.0564 116.974 77.2122 116.014C72.1955 114.76 66.047 113.836 59.2899 113.319C56.8137 115.997 54.2032 118.697 51.4564 121.415C49.3012 123.548 45.8304 123.548 43.6753 121.415C40.8789 118.648 38.2232 115.901 35.7082 113.174H35.7087ZM86.2852 45.3573C86.2852 23.9731 68.9506 6.63978 47.5677 6.63978C26.1849 6.63978 8.85026 23.9744 8.85026 45.3573C8.85026 66.531 21.6729 90.0852 47.5677 115.922C73.4628 90.0852 86.2852 66.5315 86.2852 45.3573ZM47.5677 64.1635C35.9591 64.1635 26.549 54.7534 26.549 43.1448C26.549 31.5361 35.9591 22.126 47.5677 22.126C59.1764 22.126 68.5865 31.5361 68.5865 43.1448C68.5865 54.7534 59.1764 64.1635 47.5677 64.1635ZM47.5677 57.526C55.51 57.526 61.949 51.087 61.949 43.1448C61.949 35.2025 55.51 28.7635 47.5677 28.7635C39.6255 28.7635 33.1865 35.2025 33.1865 43.1448C33.1865 51.087 39.6255 57.526 47.5677 57.526Z"
                      fill="#DA1B22"
                    />
                  </svg>
                  <h4>Head Office:</h4>
                  <p>
                    243A, first Floor, DLF Avenue, Saket District Centre, New
                    Delhi - 110017
                  </p>
                  <p>Phone- 011 4654 4289</p>
                  <h4 className="mt-5">Delhi:</h4>
                  <p>
                    406, 4th Floor, DLF Courtyard, District Center, Saket, New
                    Delhi-110017
                  </p>
                  <p>Phone- 011 46544228</p>
                  <h4 className="mt-5">Mumbai:</h4>
                  <p>
                    305, Morya Landmark-I, Opposite VIP Plaza, New Link Road
                    Andheri West Mumbai-400053
                  </p>
                  <p>Phone- 022 61669755/56</p>
                </div>
                <div className="col flex-1">
                  <form ref={form} onSubmit={sendQuery}>
                    <div className="grid md:grid-cols-2 gap-4">
                      <input
                        type="text"
                        className="md:col-span-2"
                        placeholder="NAME"
                        name="name"
                        required
                      />
                      <input
                        name="email"
                        type="email"
                        placeholder="EMAIL"
                        required
                        pattern="^.+@.+\.[a-zA-Z]{2,63}$"
                        maxlength="250"
                        aria-label="Email"
                      ></input>
                      <input
                        name="phone"
                        type="tel"
                        placeholder="PHONE"
                        pattern="^[+]?([(][0-9]{1,3}[)][-]?)?([0-9][-]?){3,16}[0-9]$"
                        maxlength="50"
                        required
                        aria-label="Phone"
                      />
                      <input
                        type="text"
                        name="city"
                        placeholder="CITY"
                        required
                      />
                      <select name="query_type" required>
                        <option value="" disabled selected>
                          TYPE OF QUERY
                        </option>
                        <option value="Athlete">For Athlete related queries</option>
                        <option value="Sales">For Sales related queries</option>
                      </select>
                      <input
                        className="md:col-span-2"
                        type="text"
                        name="subject"
                        placeholder="SUBJECT"
                        required
                      />
                      <textarea
                        className="md:col-span-2"
                        placeholder="HOW MAY I HELP YOU"
                        name="message"
                        required
                      ></textarea>
                    </div>
                    <button className="p_btn block mt-5 ml-auto">Send</button>
                  </form>
                </div>
              </div>

              {/* <h3 className="text-3xl md:text-5xl font-thin mt-20">
            Contact us via the form below and we’ll be in touch.
          </h3>

          <ul className="my-5 md:my-10">
            <li>
              <h3>For Talent </h3>
              <div className="col">
                <span>
                  Rahul Trehan -{" "}
                  <a href="mailto:rahul@iosindia.com">rahul@iosindia.com</a>
                </span>
                <span>
                  Aditi Mahajan -{" "}
                  <a href="mailto:aditi@iosindia.com">aditi@iosindia.com</a>
                </span>
              </div>
            </li>
            <li>
              <h3>For Brand Endorsements </h3>
              <span>
                Gaurav Chaudhary -{" "}
                <a href="mailto:gaurav.c@iosindia.com">gaurav.c@iosindia.com</a>
              </span>
            </li>
            <li>
              <h3>For Operations </h3>
              <span>
                Ravi Gaur -{" "}
                <a href="mailto:ravi@iosindia.com">ravi@iosindia.com</a>
              </span>
            </li>
            <li>
              <h3>For Pr & Media </h3>
              <span>
                Sumit Singh -{" "}
                <a href="mailto:sumit@iosindia.com">sumit@iosindia.com</a>
              </span>
            </li>
          </ul> */}

              <ul className="my-5 md:my-10">
                <li>
                  <h3>For Talent </h3>
                  <div className="col">
                    <span>
                      <a href="mailto:rpartnerships@iosindia.com">
                        partnerships@iosindia.com
                      </a>
                    </span>
                  </div>
                </li>
                <li>
                  <h3>FOR SALES AND MARKETING</h3>
                  <span>
                    <a href="mailto:marketing@iosindia.com">
                      marketing@iosindia.com
                    </a>
                  </span>
                </li>
              </ul>
              <iframe
                className="w-full"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14021.548146362476!2d77.2182409!3d28.5280842!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1e019c27caab%3A0x64f8808118f3ec37!2sIOS%20Sports%20%26%20Entertainment!5e0!3m2!1sen!2sin!4v1683071341607!5m2!1sen!2sin"
                height="450"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Fragment>
          )}
        </div>
      </section>
    </Fragment>
  );
}

export default Contact;
